<!-- Finder expansible de Documentos de Expedientes -->

<template>
  <div class="exp_docs_XM" v-if="schema">      
    <v-sheet :elevation="4">

      <!-- Botones Mto -->
      <div class="pt-2 d-flex justify-left">
        <!-- Mto -->            
        <btramto          
          :btra_permisos="schema.btra"
          :btra_particular="Entorno.btra"
          btra_modulo="xm"
          :estado="estado"
          @onEvent="event_btra_Mto">                
        </btramto>   

        <v-btn
          style="margin-left:15px"
          v-bind="$cfg.btra_cfg.standard" 
          :disabled="schema.ctrls.cia.value=='0'" 
          @click="envioGd"          
          title="Envio Factura MF (Gestor Documental)">
            <v-icon dark>{{ "mdi-arrow-up-thick" }}</v-icon>
        </v-btn> 
      </div>

      <!-- Controles del Mto -->
      <div class="contenedor">  
       
        <div style="display:flex"> 
          <v-text-field
            style="flex: 0 0 60%"
            v-bind="$input"
            v-model="schema.ctrls.name.value"
            :label="schema.ctrls.name.label"
            :disabled="!is_edit">
          </v-text-field>

          <v-select
            style="flex: 0 0 40%"
            v-bind="$select"
            v-model="schema.ctrls.tpd.value"
            :label="schema.ctrls.tpd.label"
            :items="itemsTipoDoc"
            :disabled="!is_edit"
            item-value="id"
            item-text="name">
          </v-select>
        </div>
        
        <v-textarea
          v-bind="$textarea"
          v-model="schema.ctrls.des.value"
          :label="schema.ctrls.des.label"
          no-resize
          rows="2"
          :disabled="!is_edit">
        </v-textarea>

        <v-file-input
          v-model="files"
          accept="application/pdf"
          chips
          clearable
          dense
          rounded
          small-chips
          :disabled="estado != 'nuevo'">
        </v-file-input>                    

        <div style="display:flex"> 
          <div>Factura/Suplido (Debe indicar si corresponse a una Factura o a un Suplido específico)</div>
        </div>

        <div style="display:flex">
          <v-select
            style="flex: 0 0 60%"
            v-bind="$select"
            v-model="get_fra"
            :label="schema.ctrls.fra_id.label"
            :items="itemsFactura"
            :disabled="!is_edit"
            item-value="d"
            item-text="n"
            @change="change_fra">
          </v-select>

          <v-checkbox            
            v-bind="$checkbox"
            v-model="schema.ctrls.ca24.value"
            :label="schema.ctrls.ca24.label"
            :disabled="!is_edit">
          </v-checkbox>

          <v-checkbox            
            v-bind="$checkbox"
            v-model="schema.ctrls.apd.value"
            :label="schema.ctrls.apd.label"
            :disabled="!is_edit">
          </v-checkbox>

          <v-checkbox            
            v-bind="$checkbox"
            v-model="schema.ctrls.jzo.value"
            :label="schema.ctrls.jzo.label"
            :disabled="!is_edit">
          </v-checkbox>

          <v-checkbox            
            v-bind="$checkbox"
            v-model="schema.ctrls.cia.value"
            :label="schema.ctrls.cia.label"
            :disabled="!is_edit">
          </v-checkbox>

          <v-checkbox            
            v-bind="$checkbox"
            v-model="schema.ctrls.fun.value"
            :label="schema.ctrls.fun.label"
            :disabled="!is_edit">
          </v-checkbox>
        </div>

        <div style="display:flex">
          <compfecha :schema="schema.ctrls.fhhr" :edicion="false"></compfecha>         
          
          <v-text-field
            v-bind="$input"
            v-model="schema.ctrls.usu.value"
            :label="schema.ctrls.usu.label"
            :disabled="true">
          </v-text-field>
        </div>
      </div>
    </v-sheet>       
  </div>
</template>



<script>

  import { mixinMto } from "@/mixins/mixinMto.js"; 
  import { mixinDocs_XM } from "@/mixins/mixinDocs_XM.js"; 
  import plugs from "@/common/general_plugs";  
  const btramto = () => plugs.groute("btramto.vue", "comp");    
  const compfecha = () => plugs.groute("compfecha.vue", "comp");
    
  export default {
    mixins: [mixinMto, mixinDocs_XM],
    components: { btramto, compfecha },
    props: {
      padre: { type:String, default: '' }, 
      id: { type:[Number, String], default: '' },   
      accion: { type:[Number, String], default: ''},
      accionRow: { type:Object, default: ()=> {}}, 
      disparo: { type:Boolean, default:false }, 
      componenteTipo: { type:String, default:'M' },
      tip: { type:Number, default:0 },    
      Entorno: { type: Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.M)); }}
    },


    data() {
      return {
        schema: null,       
        api:'exp_Docs_M',
        stName:'stMexp_docs',
        disparoFinder:false,
        
        campo_relacionado: "tip_id",
        tabla:1,
        files:null,
        itemsTipoDoc:[],       
        itemsFactura:[]        
      };
    },  


    computed: {
      get_fra: {
        get() {
          console.log('get get_fra: ', this.accionRow); 
          if (this.estado== 'nuevo') return "0";
          if (Number(this.schema.ctrls.sup_id.value)> 0) return  this.schema.ctrls.sup_id.value;
          return this.schema.ctrls.fra_id.value;
        },

        set(d) {          
          let itemFra= this.itemsFactura.find(item => item.d == d);
          this.schema.ctrls.fra_id.value= itemFra.fra;
          this.schema.ctrls.fra_id.value= itemFra.fra;
          console.log('set get_fra: ', itemFra);
        }
        
      }
    },


    methods: {
      ini_data() { 
        console.log("DEV " + this.api + " ********************** (ini_data)");

        // guardo del store en variables locales
        this.schema = this.$store.state[this.stName].schema;  
        
        // configuro Entorno        
        this.Entorno.header.header= false;    
        this.Entorno.btra= "XM";        
        
        // items adicionales
        // tipo de documento
        this.itemsTipoDoc= JSON.parse(JSON.stringify(this.$store.state.datos_iniciales.tipo_documento));
        this.itemsTipoDoc= this.itemsTipoDoc.filter(elem => elem.modulos.charAt(this.tip== 1));      
        
        // facturas expedientes
        this.itemsFactura= JSON.parse(JSON.stringify(this.$store.state[this.padre].recordsDetalle[2]));
        this.itemsFactura.unshift({ d:"0", n:"Selecciona una Factura/Suplido" });
      },


      // guardo item fra seleccionada
      change_fra(d) {             
        let itemFra= this.itemsFactura.find(item => item.d == d);
        
        // actualizo el control de suplido con el dato de la fra
        this.schema.ctrls.sup_id.value= itemFra.sup;        
        
        // marco checkbox´s de APD y JZ/COS
        this.schema.ctrls.apd.value= "1";
        this.schema.ctrls.jzo.value= "1";
        this.schema.ctrls.name.value= itemFra.n;
      },


      // pregunto si enviamos factura seleccionada al Gestor Documental
      envioGd() {
        this.$root.$alert.open('ATENCION<br>Enviamos Factura Venta (pdf) a MAPFRE (Gestor Documental)?<br>Factura: ' + this.accionRow.name, 'confirm')
        .then(r=> { if (r) this.envio_emailGd(); });
      },

      // API envio email a Gestor Documental
      async envio_emailGd() {
        let args = { tipo:'fnc', accion: 'documentos', fn_args: { accion:'envio_mf', id:this.accionRow.id, tp:1 }};
        let apiResult = await this.$store.dispatch("ajaxRequest", { args:args });
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        this.$root.$alert.open('Factura enviada correctamente', 'success', 1800);
      },

    }
};
</script>
